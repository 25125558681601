import { GrantsForm } from './GrantsForm';
import * as ReactDOM from 'react-dom/client';
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Category, CategoryDatum, NumericCategory, YourFormElement } from '../types';

interface GrantsTabulatorProps {
  categoryData: Array<CategoryDatum>;
  rangeLimits: {
    year: { min: FormDataEntryValue | number; max: number };
    amount: { min: FormDataEntryValue | number; max: FormDataEntryValue | number };
  };
}

function createCategory(categoryDatum: CategoryDatum): NumericCategory {
  return { id: Number(categoryDatum.id), shortForm: categoryDatum.shortForm, title: categoryDatum.title, note: categoryDatum.note }
}

function GrantsTabulator({ categoryData, rangeLimits }: GrantsTabulatorProps) {
  const resultsBox = useRef(null);

  const categories = categoryData.map(createCategory)

  const initialState = {
    resultsBoxOpacity: 0,
    categories: categoryData.filter(x => x.initiallySelected).map(createCategory),
    searchValue: '',
    yearRange: [rangeLimits.year.min, rangeLimits.year.max],
    amountRange: [rangeLimits.amount.min, rangeLimits.amount.max],
    sortType: 'paymentDate',
    sortOrder: 'DESC',
  };

  if (window.location.search.length > 0) {
    const params = new URLSearchParams(window.location.search);

    if (params.get('category[]')) {
      const categoryIds = params.getAll('category[]').map(Number)
      initialState['categories'] = categories.filter(category => categoryIds.includes(category.id));
    }

    if (params.get('searchQuery')) {
      initialState['searchValue'] = params.get('searchQuery');
    }

    if (params.get('minYear') && params.get('maxYear')) {
      initialState['yearRange'] = [params.get('minYear'), params.get('maxYear')].map((x) =>
        Number(x)
      );
    }

    if (params.get('minAmount') && params.get('maxAmount')) {
      initialState['amountRange'] = [params.get('minAmount'), params.get('maxAmount')].map((x) =>
        Number(x)
      );
    }

    if (params.get('sortMethod')) {
      initialState['sortType'] = params.get('sortMethod');
    }

    if (params.get('ascending')) {
      initialState['sortOrder'] = params.get('ascending');
    }
  }
  const PAGE_SIZE = 16;
  const [selectedCategories, setSelectedCategories] = useState(initialState.categories);
  const [searchValue, setSearchValue] = useState(initialState.searchValue);
  const [sliderYearRange, setSliderYearRange] = useState(initialState.yearRange);
  const [sliderAmountRange, setSliderAmountRange] = useState(initialState.amountRange);
  const [activeSortType, setActiveSortType] = useState(initialState.sortType);
  const [activeSortOrder, setActiveSortOrder] = useState(initialState.sortOrder);
  const [resultsBoxOpacity, setResultsBoxOpacity] = useState(initialState.resultsBoxOpacity);

  let fetchTimeout;

  const grantsReturnedRef = useRef(0);
  const selectedCategoriesRef = useRef(initialState.categories);
  const activeSortOrderRef = useRef(initialState.sortOrder);
  const activeSortTypeRef = useRef(initialState.sortType);
  const sliderAmountRangeRef = useRef(initialState.amountRange);
  const sliderYearRangeRef = useRef(initialState.yearRange);
  const searchValueRef = useRef(initialState.searchValue);

  function buildFormData(appendResults = false) {
    const fd = new FormData();
    selectedCategoriesRef.current.forEach(category => fd.append('category[]', category.id.toString()))
    fd.append('ascending', activeSortOrderRef.current);
    if (activeSortType !== '') {
      fd.append('sortMethod', activeSortTypeRef.current);
    }
    fd.append('minAmount', sliderAmountRangeRef.current[0].toString());
    fd.append('maxAmount', sliderAmountRangeRef.current[1].toString());
    fd.append('minYear', sliderYearRangeRef.current[0].toString());
    fd.append('maxYear', sliderYearRangeRef.current[1].toString());
    fd.append('searchQuery', searchValueRef.current);
    fd.append('limit', PAGE_SIZE.toString());
    if (appendResults) {
      fd.append('offset', grantsReturnedRef.current.toString());
    }
    return fd;
  }

  const queryBackend = (appendResults = false) => {
    const fd = buildFormData(appendResults);

    fetch('/grants/api', {
      method: 'POST',
      body: fd,
    })
      .then((response) => response.text())
      .then((text) => {
        if (resultsBox && resultsBox.current) {
          // This is for a fade-in on the initial page load.
          setResultsBoxOpacity(1);
          const pageNode = document.createElement('div');
          pageNode.innerHTML = text;
          if (appendResults) {
            grantsReturnedRef.current += pageNode.children.length;
            for (let i = 0; i < pageNode.children.length; i++) {
              const grantNode = pageNode.children[i];
              grantNode.classList.add('fade-in');
              resultsBox.current.appendChild(grantNode);
            }
          } else {
            grantsReturnedRef.current = pageNode.children.length;
            resultsBox.current.innerHTML = text;
          }
        }
      });
  };

  const copyQueryString = async () => {
    const fd = buildFormData();
    const queryString = new URLSearchParams(fd).toString();
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}${window.location.pathname}?${queryString}`
      );
      alert('A shareable URL of your search has been copied to your clipboard.');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleSubmit = (event: React.FormEvent<YourFormElement>) => {
    event.preventDefault();
    const fd = buildFormData();
    const queryString = new URLSearchParams(fd).toString();
    const newUrl = `${window.location.origin}${window.location.pathname}?${queryString}`
    window.location.href = newUrl;
  };

  // Set up an intersection observer that grabs more grants when the footer comes into view.
  useEffect(() => {
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          queryBackend(true);
        }
      });
    };

    const options = {
      threshold: 0,
      rootMargin: `0px 0px 0px 0px`,
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(document.querySelector('footer'));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    selectedCategoriesRef.current = selectedCategories;
    activeSortTypeRef.current = activeSortType
    activeSortOrderRef.current = activeSortOrder
    sliderAmountRangeRef.current = sliderAmountRange
    sliderYearRangeRef.current = sliderYearRange
    searchValueRef.current = searchValue
    fetchTimeout = setTimeout(queryBackend, 250);
    return () => clearTimeout(fetchTimeout);
  }, [
    selectedCategories,
    activeSortType,
    activeSortOrder,
    sliderAmountRange,
    sliderYearRange,
    searchValue,
  ]);

  return (
    <div className="layout-grid grants-grid">
      <div className="grants-grid__form-ui sticky-grid-sidebar">
        <GrantsForm
          categories={categories}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          sliderYearRange={sliderYearRange}
          setSliderYearRange={setSliderYearRange}
          sliderAmountRange={sliderAmountRange}
          setSliderAmountRange={setSliderAmountRange}
          activeSortType={activeSortType}
          setActiveSortType={setActiveSortType}
          activeSortOrder={activeSortOrder}
          setActiveSortOrder={setActiveSortOrder}
          rangeLimits={rangeLimits}
          copyQueryString={copyQueryString}
          handleSubmit={handleSubmit}
        />
      </div>
      <div
        className="layout-grid__flow-content"
        style={{ opacity: resultsBoxOpacity, transition: 'opacity 400ms' }}
        ref={resultsBox}
      />
    </div>
  );
}

function onDOMLoaded() {
  const grantsTabFormRoot = document.getElementById('grants-tabulator-root');
  grantsTabFormRoot
  if (grantsTabFormRoot) {
    const formEl = document.getElementById('grant-form-ui-inputs') as HTMLFormElement;
    const fd = new FormData(formEl);
    const rangeLimits = {
      year: { min: Number(fd.get('earliest-grant')), max: new Date().getFullYear() },
      amount: { min: Number(fd.get('smallest-grant')), max: Number(fd.get('largest-grant')) },
    };
    const categories = fd.getAll('category').map((x) => JSON.parse(x));
    ReactDOM.createRoot(grantsTabFormRoot).render(
      <GrantsTabulator categoryData={categories} rangeLimits={rangeLimits} />
    );
  }
}

export { GrantsTabulator, onDOMLoaded };
